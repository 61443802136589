.card{
	text-align: left;
}

.dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
}

th.clickable {
    cursor: pointer;

}